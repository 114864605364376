<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    color?: string | null;
  }>(),
  { color: '#b2b3b3' }
);

const colorStyle = computed(() => `background-color: ${props.color}`);
</script>

<template>
  <span class="bullet" :style="colorStyle"></span>
</template>

<style scoped lang="scss">
.bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: var(--app-br-full);
  flex-shrink: 0;
}
</style>
