<script setup lang="ts">
export type PartnerLocationType = 'personal' | 'company';

const props = defineProps<{
  type: PartnerLocationType;
}>();
</script>

<template>
  <div class="badge" :class="`badge_type_${props.type}`">
    {{ props.type === 'personal' ? 'Частный' : 'Салон' }}
  </div>
</template>

<style scoped lang="scss">
.badge {
  display: inline;
  font-size: 0.75em;
  padding: var(--app-spacing-1) var(--app-spacing-2);
  border-radius: var(--app-br-full);
  border: 2px solid var(--badge-color);
  color: var(--badge-color);

  &_type_personal {
    --badge-color: #46a4b6;
  }

  &_type_company {
    --badge-color: #df977a;
  }
}
</style>
