<script setup lang="ts">
import type { Coords } from '@glow/shared/lib/coords';
import type { RouteLocationRaw } from 'vue-router';
import ClosestStations, {
  type ClosestMetroStation,
} from './ClosestStations.vue';
import PartnerLocationTypeBadge, {
  type PartnerLocationType,
} from './PartnerLocationTypeBadge.vue';

export type PartnerLocationSelectItemLocation = {
  id: number;
  type: PartnerLocationType;
  name: string;
  link?: RouteLocationRaw;
  address: string;
  location: Coords;
  metroStations?: ClosestMetroStation[];
};

const props = withDefaults(
  defineProps<{
    location: PartnerLocationSelectItemLocation;
    maxStations?: number;
  }>(),
  { maxStations: 2 }
);

const metroStations = computed(
  () => props.location.metroStations?.slice(0, props.maxStations) ?? []
);
</script>

<template>
  <div class="location">
    <NuxtLink
      :to="props.location.link"
      class="font-bold text-black d-flex align-items-center gap-2"
    >
      <div class="overflow-ellipsis">{{ location.name }}</div>
      <PartnerLocationTypeBadge :type="props.location.type" />
    </NuxtLink>
    <address
      :to="props.location.link"
      class="text-black-secondary overflow-ellipsis"
    >
      {{ location.address }}
    </address>
    <ClosestStations
      class="location__stations"
      :metro-stations="metroStations"
      :location="location.location"
    />
  </div>
</template>

<style scoped lang="scss">
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location {
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: var(--app-spacing-2);

  &__stations {
    flex-direction: row;
    gap: var(--app-spacing-3);

    & > :nth-child(n + 3) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      & > :nth-child(n + 2) {
        display: none;
      }
    }
  }
}
</style>
