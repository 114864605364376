<script setup lang="ts">
import { capitalize } from 'lodash-es';
import { secondsToMinutes } from 'date-fns';
import { WalkIcon } from '@glow/shared/components/icons';
import MetroStationBullet from './MetroStationBullet.vue';
import type { Coords } from '@glow/shared/lib/coords';

export type ClosestMetroStation = {
  name: string;
  color?: string | null;
  location: Coords;
};

const props = defineProps<{
  metroStations: ClosestMetroStation[];
  location: Coords;
}>();

function haversineDistance(location1: Coords, location2: Coords): number {
  const EARTH_RADIUS_KM = 6371;

  const deltaLatitude = ((location2[0] - location1[0]) * Math.PI) / 180;
  const deltaLongitude = ((location2[1] - location1[1]) * Math.PI) / 180;

  const halfChordLength =
    Math.cos((location1[0] * Math.PI) / 180) *
      Math.cos((location2[0] * Math.PI) / 180) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2) +
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2);

  const angularDistance =
    2 * Math.atan2(Math.sqrt(halfChordLength), Math.sqrt(1 - halfChordLength));

  return EARTH_RADIUS_KM * angularDistance;
}

function calculateTimeToWalk(location: Coords) {
  const METERS_PER_KM = 1000;
  const WALK_SPEED_MPS = 1.4;

  const distance = haversineDistance(location, props.location) * METERS_PER_KM;
  const timeToWalk = distance / WALK_SPEED_MPS;
  return Math.round(secondsToMinutes(timeToWalk));
}
</script>

<template>
  <div class="stations">
    <div
      v-for="(station, index) in props.metroStations"
      :key="index"
      class="station"
    >
      <MetroStationBullet class="mt-1" :color="station.color" />
      <span class="station__name">
        <span class="mr-1">
          {{ capitalize(station.name) }}
        </span>
        <span class="station__walk">
          <AppIcon :icon="WalkIcon" size="sm" />
          {{ calculateTimeToWalk(station.location) }} мин
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stations {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-1);
}

.station {
  display: flex;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  gap: var(--app-spacing-2);

  &__name {
    display: inline;
    vertical-align: middle;
  }

  &__walk {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    color: var(--app-color-black-tertiary);
  }
}
</style>
