import { getFragmentData } from '@/gql';
import { type FragmentType } from '@/gql/fragment-masking';
import { type DocumentTypeDecoration } from '@graphql-typed-document-node/core';
import { isNil } from 'lodash-es';

export function useFragmentArrayData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>[]
  >
): Readonly<Ref<TType[]>>;
export function useFragmentArrayData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>[] | null
  >
): Readonly<Ref<TType[] | null>>;
export function useFragmentArrayData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>[] | undefined
  >
): Readonly<Ref<TType[] | undefined>>;
export function useFragmentArrayData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>[] | null | undefined
  >
): Readonly<Ref<TType[] | null | undefined>>;

export function useFragmentArrayData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>[] | null | undefined
  >
): Readonly<Ref<TType[] | null | undefined>> {
  return computed(() => {
    const fragmentTypeValue = toValue(fragmentType);
    if (isNil(fragmentTypeValue)) return fragmentTypeValue;
    return fragmentTypeValue.map((value) =>
      getFragmentData(documentNode, value)
    );
  });
}
